.UploadPortal {}

.succeed {
    color: green;
}
.failed {
    color: darkred;
}
.pending {
    color:black;
}
.uploading {
    color:#f5f2f5;
}

.ant-spin-dot-item {
    background-color: white !important;
}
.ant-spin {
    margin-left: 10px !important;
}

.ant-upload-list-item-progress {
    width: calc(100% - 50px) !important;
}
