@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");
@import "~antd/dist/antd.css";

:root {
  --text-color: #2a023d;
  --website: #f5f2f5;
  --white: #e8e3eb;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Layout CSS */
.ant-layout {
  background: var(--white);
	}

.ant-layout-header,
.ant-layout-footer {
  text-align: center;
  background: var(--text-color);
  color: var(--white);
}

/* Header CSS */
.header_space {
  width: 100%;
  justify-content: space-between;
  align-items: inherit;
}
.header_space_brand {
  padding: 0px;
  color: var(--white);
}
.header_space_brand:hover {
  color: var(--white);
}

/* Profile Page CSS */
.profile_page_card {
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  border-radius: 4px;
}

.profile_save_btn {
  display: block;
  margin-left: auto;
}

.login_submit_btn {
  display: block;
  width: 100%;
}

.alert_error {
  margin-bottom: 8px;
}

.form_help_text {
  text-align: center;
}


element.style {
}

.ant-btn-primary {
  border-color: var(--website) !important;
  background: var(--website) !important;
  color: var(--text-ccolor) !important;
}
.ant-btn > span {
    display: inline-block;
}
.ant-layout, .ant-layout * {
    box-sizing: border-box;
}
*, *::before, *::after {
    box-sizing: border-box;
}
a.ant-btn {
    padding-top: 0.01px !important;
    line-height: 30px;
}
.ant-btn-link {
  color: white !important;
}
